@font-face {
  font-family: "Geist";
  src: url("fonts/GeistMonoVF.woff2") format("woff2");
}

:root {
  --foreground: #202020;
  --background: #EEEEEE;
}

body {
  margin: 0 auto;
  max-width: 768px;
  color: var(--foreground);
  background: var(--background);
}

main,
header,
footer {
  margin: 2rem;
}

a {
  color: var(--foreground);
  text-decoration: none;
  white-space: nowrap;
}

canvas {
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* tablet breakpoint */
@media (min-width:768px) {
  footer {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr  1fr;
  }

  main,
  header,
  footer {
    margin: 0;
  }

  body {
    padding-bottom: 2rem;
  }

  html {
    font-size: 137.5%;
  }
}

